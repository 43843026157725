<template>

 
  <Teleport to="#app">
    <UiModal
      :title="'Statistic'"   
      close-button-name="Close"
      :width="'100%'"
      :max-width="'900px'"
      :is-auto-height="true"
      @close="close()"  
      >        

      <div :class="{'chart_box': true, 'gloading': is_loading}">
        <div class="filters">

          <UiInput
            label="From date"
            :text="dateStart"
            type="date"
            :timeout="800"
            @change:text="dateStart = $event"
            /> 
            
          <UiInput
            label="To date"
            :text="dateEnd"
            type="date"
            :timeout="800"
            @change:text="dateEnd = $event"
            /> 
              
        </div>
        
        <Chart 
          v-if="banner_statistic?.banners_stat"
          :data="banner_statistic?.banners_stat"
        />
        
      </div>
      
    </UiModal>
  </Teleport>
</template>


<script setup>

import Chart from '@/pages/brands-manager/components/Chart'

 import { watchArray } from '@vueuse/core'

const emits = defineEmits(['close', 'choose']);
const { t } = useI18n();
const { $api } = useNuxtApp();

const currentDate = new Date();
const dateStart = ref(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate()).toISOString().substr(0, 10));
const dateEnd = ref(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()).toISOString().substr(0, 10));
      
const close = async () => {
  emits('close');
}

const loadResults = async () => {
  return await $api.getB2BBannerStatisticPackage({
    from: dateStart.value,
    to: dateEnd.value
  });     
}

const { pending: is_loading,  refresh: refreshData, data: banner_statistic } = await useLazyAsyncData('banner_statistic', async () => await loadResults())




watchArray([dateStart, dateEnd], async ([new_dateStart, new_dateEnd], added, removed) => {
  console.log('watcher');
  // console.log(banner);

  refreshData();

  // if(new_start == 0){
  //   search_data.value = [];

  // } 

  // is_loading.value = true;
  // var dt = await loadResults();

  // if(new_start == 0){
  //   is_ended.value = false;
  // } 

  // if(dt.length == 0)
  //   is_ended.value = true;  

  // if(dt?.length > 0)
  //   search_data.value = [...search_data.value, ...dt]

  // is_loading.value = false;

}, {deep: true})



 
</script>
 


<style scoped>
   

/* form */


.filters{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
}
 

.chart_box{
  position: relative;
}


</style>